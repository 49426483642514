@import '~assets/styles/variables';

.deliverable {
  background-color: $white;
  margin-top: $size-3;

  header {
    background-color: $grey-dark;
    color: $white;
    padding: 0 $size-4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    line-height: 2;
    h3 {
      font-size: 14px;
    }
  }

  .progress:not(:last-child) {
    height: 5px;
  }
}

.date {
  font-size: 12px;
  line-height: 30px;
}

