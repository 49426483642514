@import '~assets/styles/variables';

.list {
  li {
    border-top: 1px solid $grey-lighter;
    &:last-of-type {
      border-bottom: 1px solid $grey-lighter;
    }
  }
}

.listTitle {
  font-size: 11px;
  padding: 0 $size-4;
  font-weight: bold;
  line-height: 2;
  margin-top: $size-5;
  text-transform: uppercase;
}
