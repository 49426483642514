@import '~assets/styles/variables';

.app {
  background-color: #F1F1F1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}
