@import '~assets/styles/variables';

.listItem {
  font-size: 15px;
  line-height: 3;
  font-weight: 300;

  a {
    color: $text;
    padding: 0 $size-4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .progress {
    height: 5px;
  }
}

.listItemName {
  flex-grow: 1;
}

.date {
  font-size: 12px;
}

.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $size-4;
  &:hover {
    background-color: $grey-lighter;
  }
}

.description {
  font-size: 12px;
  margin-left: $size-6;
  align-self: flex-start;
}

.checkListItem {
  cursor: pointer;
  input {
    margin-right: 14px;
  }
}

.icon {
  width: 26px;
  img {
    display: block;
    height: 16px;
  }
  i {
    color: $grey-dark;
    font-size: 20px;
    margin-left: -1px;
    vertical-align: text-bottom;
  }
}
