
@import '~assets/styles/variables';

.back {
  font-size: 12px;
  color: $text;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $size-6;
  i {
    font-size: 20px;
    margin-right: 5px;
    display: block;
  }
}