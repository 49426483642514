@import '~assets/styles/variables';

.clientLogo {
  max-height: 50px;
  margin-right: $size-1;
}

.summary {
  margin-top: $size-3;

  header {
    padding: $size-4;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  dl {
    font-weight: 300;
    flex-grow: 1;
  }
  dt {
    float: left;
    width: 90px;
  }
  dd {
    font-weight: normal;
  }
}
