$red: #9d2335;
$cyan: #5DCFCD;
$yellow: #F5A623;
$grey-light: #D8D8D8;
$grey-lighter: #EBEBEB;
$grey-dark: #979797;
$text: #4A4A4A;
$primary: $cyan;
$family-primary: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
$box-radius: 0;
$box-shadow: none;
$progress-value-background-color: $grey-dark;

@import "~bulma/sass/utilities/all";
